<template>
  <div class="StepWelcome">
    <span class="Header">Добро пожаловать в новый профиль!</span>
    <span class="Description"
      >Чтобы тебе было удобнее в нём ориентироваться, мы подготовили для тебя
      небольшое обучение.</span
    >
    <span
      class="UseRestyledButtonCSSGradient"
      @click="changeStepHandle('ProfileProjectCard')"
      >Далее</span
    >
  </div>
</template>

<script>
// import TasksList from "@/components/Profile/TasksList.vue";

export default {
  name: "StepWelcome",
  props: {
    changeStepHandle: Function,
    handleEducationFinish: Function,
    showComponent: Function,
    scrollToElement: Function,
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  mounted() {
    this.showComponent("");
    this.scrollToElement();
  },
  methods: {},
};
</script>

<style scoped>
.StepWelcome {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
}

.StepWelcome > * {
  margin-left: auto;
  margin-right: auto;
}
.StepWelcome > *:nth-child(1) {
  margin-top: 20vh;
}
.StepWelcome > *:nth-last-child(1) {
  margin-bottom: auto;
}

.Header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 160%;

  /* or 77px */
  text-align: center;

  color: #ffffff;
}

.Description {
  position: relative;

  margin-top: 60px;
  margin-bottom: 30px;

  width: 800px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  text-align: center;

  color: #ffffff;
}
</style>

<style scoped>
@media (min-width: 992px) and (max-width: 1600px) {
  .Header {
    font-size: 35px;
  }

  .Description {
    margin-top: 60px;
    margin-bottom: 30px;

    width: 800px;
    font-size: 20px;
  }
}
</style>
