<template>
  <div id="EducationWrapper" v-if="education_show">
    <div
      class="EducationScreenBlocker"
      v-show="education_show"
      @wheel.prevent
      @touchmove.prevent
      @scroll.prevent
    />
    <div class="EducationScreenWrapper" v-show="education_show">
      <!-- Профиль, Обучение -->
      <StepWelcome
        v-if="education_step_name == 'Welcome'"
        :changeStepHandle="handleChangeStep"
        :handleEducationFinish="handleCloseEducation"
        :showComponent="showComponent"
        :scrollToElement="scrollToTop"
      />
      <StepProfileProjectCard
        v-if="education_step_name == 'ProfileProjectCard'"
        :changeStepHandle="handleChangeStep"
        :handleEducationFinish="handleCloseEducation"
        :showComponent="showComponent"
        :scrollToElement="scrollMeTo"
      />
      <StepProfileCompetenceBlock
        v-if="education_step_name == 'ProfileCompetenceBlock'"
        :changeStepHandle="handleChangeStep"
        :handleEducationFinish="handleCloseEducation"
        :showComponent="showComponent"
        :scrollToElement="scrollMeTo"
      />
      <!-- Профиль, Обучение END -->
    </div>
  </div>
</template>

<script>
import { createSetting, removeSetting, getUserSettings } from "@/api/setting.js";
import { mapGetters } from "vuex";
import StepWelcome from "@/views/ProfileEducation/steps/StepWelcome.vue";
import StepProfileProjectCard from "@/views/ProfileEducation/steps/StepProfileProjectCard.vue";
import StepProfileCompetenceBlock from "@/views/ProfileEducation/steps/StepProfileCompetenceBlock.vue";

export default {
  name: "EducationScreen",
  props: {
    showEducation: Boolean,
    showComponent: Function,
    educationStartStep: String,
  },
  data() {
    return {
      education_show: false,
      education_step_name: "",
    };
  },
  components: {
    StepWelcome,
    StepProfileProjectCard,
    StepProfileCompetenceBlock,
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
  },
  mounted() {
    this.education_step_name = this.educationStartStep;
    this.education_show = this.showEducation;
  },
  methods: {
    handleCloseEducation() {
      this.education_show = false;
      this.showComponent("");
      this.scrollToTop();

      createSetting({ user: this.user.id, setting: 1 }).then((res) => {
        console.log(res);
      });
    },
    handleChangeStep(NextStepName) {
      this.education_step_name = NextStepName;
    },

    scrollMeTo(SelectorName) {
      let elem = document.querySelector(SelectorName);
      elem.scrollIntoView({ block: "start", behavior: "smooth" });
    },

    scrollToTop() {
      let elem = document.querySelector("#PageStart");
      elem.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
#EducationWrapper {
  position: absolute;
  width: 100%;
  height: 100vh;

  backdrop-filter: blur(4px);
}
.EducationScreenBlocker {
  position: absolute;

  width: 100%;
  height: 100vh;

  z-index: 500;

  background-color: rgba(0, 0, 0, 0.75);
}
.EducationScreenWrapper {
  position: absolute;

  width: 100%;
  height: 100vh;

  z-index: 1000;

  overflow: hidden;
}
</style>

<style scoped>
@media (max-width: 992px) {
  #EducationWrapper {
    display: none;
  }
}
</style>

<style>
.hidePageScroll {
  overflow: hidden;
}
.ShowOnEducation {
  position: relative;

  pointer-events: none;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  z-index: 800;
}
</style>
