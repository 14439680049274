<template>
  <div class="StepProfileProjectCard">
    <span class="Header">Компетенции</span>
    <div class="EduContent">
      <span class="Description"
        >Это карточка твоих компетенций. Те, что подсвечены розовым — нужно
        будет пройти, остальные — по желанию. Важно закрыть подсвеченные
        компетенции, только потом тебя ждёт следующий этап — реализации проекта.
      </span>

      <div class="EduTips">
        <div class="EduTip">
          <div class="IconBlock">
            <div class="lvl"></div>
          </div>
          <span class="TipText">Неосвоенная компетенция</span>
        </div>
        <div class="EduTip">
          <div class="IconBlock">
            <div class="lvl lvl-for-project"></div>
          </div>
          <span class="TipText"
            >Неосвоенная компетенция, но нужна для юнита</span
          >
        </div>
        <div class="EduTip">
          <div class="IconBlock">
            <div class="lvl lvl-painted"></div>
          </div>
          <span class="TipText">Освоенная компетенция</span>
        </div>
      </div>
    </div>

    <div class="buttons">
      <span
        class="UseRestyledButtonCSS"
        @click="changeStepHandle('ProfileProjectCard')"
        >Назад</span
      >
      <span class="UseRestyledButtonCSSGradient" @click="handleEducationFinish"
        >Завершить</span
      >
    </div>
  </div>
</template>

<script>
// import TasksList from "@/components/Profile/TasksList.vue";

export default {
  name: "StepProfileCompetenceBlock",
  props: {
    changeStepHandle: Function,
    handleEducationFinish: Function,
    showComponent: Function,
    scrollToElement: Function,
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  mounted() {
    this.showComponent("ProfileCompetenceBlock");
    this.scrollToElement("#ProfileCompetenceBlock");
  },
  methods: {},
};
</script>

<style scoped>
.TipText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: left;

  color: #ffffff;
}

.IconBlock {
  position: relative;
  display: flex;
}
.lvl {
  display: flex;
  width: 36px;
  height: 32px;
  background: #312e57;
  border-radius: 30%;

  margin: 16px 16px 16px 0%;
}

.lvl-for-project {
  border: 2px solid #b434b5;
}
.lvl-painted {
  background: #b434b5;
}
.EduTip {
  position: relative;

  height: fit-content;
  max-width: 380px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.EduTip > * {
  margin-top: auto;
  margin-bottom: auto;
}

.EduTips {
  position: relative;

  margin-top: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.EduContent {
  position: relative;

  margin-bottom: 30px;

  width: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.StepProfileProjectCard > .buttons > .UseRestyledButtonCSSGradient {
  height: fit-content !important;
}

.StepProfileProjectCard {
  position: relative;

  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 76px;

  padding-bottom: 10px;

  width: fit-content;
  height: 100%;

  overflow: hidden;
}

.Header {
  width: auto;
  max-width: 380px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;

  /* or 77px */
  text-align: left;

  color: #ffffff;
}

.Description {
  position: relative;

  margin-top: 6px;

  width: auto;
  max-width: 380px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: left;

  color: #ffffff;
}

.StepProfileProjectCard > .buttons {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  width: 100%;
  height: fit-content;
}

.StepProfileProjectCard > .buttons > .UseRestyledButtonCSS:nth-child(1) {
  margin-right: 10px;
}
</style>

<style scoped>
@media (min-width: 992px) and (max-width: 1200px) {
  .StepProfileProjectCard {
    padding: 76px;
    margin-left: calc(50%);
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .StepProfileProjectCard {
    padding: 76px;
    margin-left: calc(60%);
  }
}
</style>
