<template>
  <div class="StepProfileProjectCard">
    <span class="Header">Проект</span>
    <div class="EduContent">
      <span class="Description"
        >Это карточка твоего проекта, а также информация о нём и компетенциях,
        требуемых для проекта. Если ты захочешь изменить тему проекта нажми на
        кнопку «сменить свой проект».</span
      >

      <div class="ChangeButtonTip">
        <div class="InfoProject"></div>
        <span class="TipText">- «сменить свой проект»</span>
      </div>
    </div>

    <div class="buttons">
      <span class="UseRestyledButtonCSS" @click="changeStepHandle('Welcome')"
        >Назад</span
      >
      <span
        class="UseRestyledButtonCSSGradient"
        @click="changeStepHandle('ProfileCompetenceBlock')"
        >Далее</span
      >
    </div>
  </div>
</template>

<script>
// import TasksList from "@/components/Profile/TasksList.vue";

export default {
  name: "StepProfileProjectCard",
  props: {
    changeStepHandle: Function,
    handleEducationFinish: Function,
    showComponent: Function,
    scrollToElement: Function,
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  mounted() {
    this.showComponent("ProfileProjectCard");
    this.scrollToElement("#ProfileProjectCard");
  },
  methods: {},
};
</script>

<style scoped>
.TipText {
  position: relative;

  margin-left: 10px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: left;

  color: #ffffff;
}

.ChangeButtonTip {
  position: relative;
  display: flex;
  flex-direction: row;

  margin-top: 20px;

  justify-content: flex-start;
}
.ChangeButtonTip > * {
  margin-bottom: auto;
  margin-top: auto;
}

.EduContent {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-bottom: 30px;
}

.InfoProject {
  position: relative;
  width: 44px;
  height: 40px;
  margin: 6px;

  background-image: url("./../../../assets/img/profile/ProfileProjectCard/Leave.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.StepProfileProjectCard > .buttons > .UseRestyledButtonCSSGradient {
  height: fit-content !important;
}

.StepProfileProjectCard {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 76px;

  padding-top: 50px;

  width: fit-content;
  height: 100%;
}

.Header {
  width: auto;
  max-width: 380px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;

  /* or 77px */
  text-align: left;

  color: #ffffff;
}

.Description {
  position: relative;

  margin-top: 6px;

  width: auto;
  max-width: 380px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: left;

  color: #ffffff;
}

.StepProfileProjectCard > .buttons {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  width: 100%;
  height: fit-content;
}

.StepProfileProjectCard > .buttons > .UseRestyledButtonCSS:nth-child(1) {
  margin-right: 10px;
}
</style>

<style scoped>
@media (min-width: 992px) and (max-width: 1200px) {
  .StepProfileProjectCard {
    padding: 76px;

    padding-top: 40px;

    margin-left: calc(50%);
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .StepProfileProjectCard {
    padding: 76px;

    padding-top: 40px;

    margin-left: calc(60%);
  }
}
</style>
